<template>
  <div class="search">
    <div class="search-box flex-between">
      <div class="left" @click="$router.go(-1)"></div>
      <div class="center flex">
        <div class="search-icon"></div>
        <input class="input" v-model="keyWords" type="text" placeholder="搜索好片...">
      </div>
      <div @click="searchGo" class="right">搜索</div>
    </div>
    <div class="section">
      <div class="tag" v-if="hotList.length>0">
        <div class="title">热搜主题</div>
        <div class="tag-content flex-between">
          <router-link tag="div" :to="{path:'/searchResult',query:{name:item}}" class="item ellipsis" v-for="(item,index) in hotList" :key="index">
            <span class="rank">{{index+1}}</span>{{item}}
          </router-link>
        </div>
      </div>
      <div class="tag" v-if="hotTags.length > 0">
        <div class="title">热搜标签</div>
        <div class="tag-content flex">
          <router-link tag="div" :to="{path:'searchTagResult',query:{name:item.name}}" class="hot-tag" v-for="item in hotTags" :key="item.id">{{item.name}}</router-link>
        </div>
      </div>
      <div class="tag" v-if="hotBloggers.length > 0">
        <div class="title">热搜博主</div>
        <div class="tag-content flex-between">
          <router-link tag="div" :to="{path:'/bloggerDetails',query: { id: item.id }}" class="item user flex" v-for="(item,index) in hotBloggers" :key="item.id">
            <span class="rank-red">{{index+1}}</span><ImgDecypt :round="true" class="header" :src="item.avatarUrl"/><span class="user-des ellipsis">{{item.nickName}}</span><span class="bao" :class="{re:index>3}">{{index>3?'热':'爆'}}</span>
          </router-link>
        </div>
      </div>
      <van-loading type="spinner" color="#f21313" v-show="hotList.length==0&&hotTags.length==0&&hotBloggers.length==0"/>
    </div>
  </div>
</template>
<script>
import {getSearchHot,getHotTags,geHotBloggers} from '@/api/app.js';
export default {
  data(){
    return{
      hotList:[],//热搜主题
      hotTags:[],//热搜标签
      hotBloggers:[],//热搜博主
      keyWords:'',//搜索关键字
    }
  },
  created(){
    this.getSearchHot();
    this.getHotTags();
    this.geHotBloggers();
  },
  methods:{
    searchGo(){//点击搜索按钮
      if(!this.keyWords.trim()){
        this.$toast('请输入您要搜索的内容');
      }else{
        this.$router.push({path:'/searchResult',query:{name:this.keyWords}});
      }
    },
    async geHotBloggers(){//热搜博主
      let ret = await this.$Api(geHotBloggers,{limit:10});
      if(ret.code == 200){
        this.hotBloggers = ret.data.bloggers;
      }
    },
    async getHotTags(){//热门标签
      let ret = await this.$Api(getHotTags,undefined);
      if(ret.code == 200){
        let list = ret.data ? ret.data :[];
        this.hotTags = list;
      }
    },
    async getSearchHot(){//热搜主题
      let ret = await this.$Api(getSearchHot,undefined);
      if(ret.code == 200){
        let list = ret.data.contents ? ret.data.contents :[];
        this.hotList = list;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  width:264px;
  height:32px;
  border-radius:18px;
  background:#ffffff;
  align-items: center;
}
.right{
  font-size:15px;
  color:#fff;
}
.search-icon{
  width:20px;
  height:20px;
  background:url('../../assets/png/search_img.png') center center no-repeat;
  background-size:70%;
  margin-left:12px;
  margin-right:10px;
}
.input{
  height:100%;
  flex:1;
  font-size:13px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.title{
  margin-top:10px;
  font-size:15px;
  font-weight: bold;
}
.tag{
  overflow: hidden;
}
.tag-content{
  margin-top:15px;
  flex-wrap: wrap;
  margin-bottom:20px;
}
.item{
  width:160px;
  color:$vermillion;
  border-bottom:1px solid #d9d9d9;
}
.rank{
  display: inline-block;
  width:18px;
  height:18px;
  text-align: center;
  line-height: 18px;
  color:#ffffff;
  margin:10px 12px 2px 5px;
  font-size:15px;
  background:linear-gradient(to bottom,#f74949,#c92323);
  border-radius: 5px;
  vertical-align:-2px;
}
.item:nth-of-type(2)>.rank{
  background:linear-gradient(to bottom,#f8c449,#d3a840);
}
.item:nth-of-type(3)>.rank{
  background:linear-gradient(to bottom,#ec69ff,#b351c3);
}
.item:nth-of-type(4)>.rank{
  background:linear-gradient(to bottom,#6a2bf2,#773ec4);
}
.item:nth-of-type(5)>.rank{
  background:linear-gradient(to bottom,#2be6e6,#1dadad);
}
.item:nth-of-type(6)>.rank{
  background:linear-gradient(to bottom,#3185e8,#19549a);
}
.item:nth-of-type(7)>.rank{
  background:linear-gradient(to bottom,#49f764,#3cc74f);
}
.item:nth-of-type(8)>.rank{
  background:linear-gradient(to bottom,#e8f019,#cbd834);
}
.item:nth-of-type(9)>.rank{
  background:linear-gradient(to bottom,#ff52f6,#be26ad);
}
.item:nth-of-type(10)>.rank{
  background:linear-gradient(to bottom,#ea8b1b,#f59b1f);
}
.hot-tag{
  padding:8px 14px;
  border:1px solid #fc1520;
  border-radius:17px;
  color:$vermillion;
  margin:7px 10px 0 0;
}
.user{
  border:none;
  margin-top:10px;
  align-items: center;
  color:#0c0b0b;
}
.rank-red{
  color:$vermillion;
  font-size:15px;
}
.header{
  width:20px;
  height:20px;
  border-radius:50%;
  margin:0 4px 0 8px;
  border:1px solid #F21313;
}
.bao{
  margin-left:4px;
  padding:1px 3px;
  background:linear-gradient(to bottom,#ff0f60,#e27911);
  color:#ffffff;
  border-radius: 2px;
}
.re{
  background:linear-gradient(to right,#f02369,#e27911);
}
.user-des{
  max-width: 60px;
}
</style>
